<template>
  <div class="voucher-cumluative-value">
    <div
      class="item-cumulative"
      v-for="(cumluativeValueItem, index) in cumluativeValueItems"
      :key="index"
    >
      <b-badge variant="success" class="bage-quantity">{{
        cumluativeValueItem.cumluativeQuantity
      }}</b-badge>
      <b-badge pill variant="warning"
        >{{ cumluativeValueItem.cumulativeValue }}%</b-badge
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.voucher-cumluative-value {
  .bage-quantity {
    padding: 0.25rem 0.75rem;
    margin-bottom: 1px;
  }
  .item-cumulative {
    display: grid;
    margin-right: 2px;
  }
  display: flex;
}
</style>

<script>
import { orderBy } from 'lodash';

export default {
  props: ['cumluativeValues'],
  data() {
    return {};
  },
  computed: {
    cumluativeValueItems() {
      if (!this.cumluativeValues || !this.cumluativeValues.length) {
        return [];
      }
      const orderCumluativeValues = orderBy(
        this.cumluativeValues,
        ['quantityFrom'],
        ['asc'],
      );
      return orderCumluativeValues.map((cumluativeValueItem) => {
        let cumluativeQuantity = cumluativeValueItem.quantityFrom;
        if (
          cumluativeValueItem.quantityFrom !== cumluativeValueItem.quantityTo
        ) {
          cumluativeQuantity = `${cumluativeValueItem.quantityFrom} - ${cumluativeValueItem.quantityTo}`;
        }
        return {
          cumluativeQuantity,
          cumulativeValue: cumluativeValueItem.cumulativeValue,
        };
      });
    },
  },
};
</script>